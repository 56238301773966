import React, { useContext } from 'react';
import { AppContext } from '../contexts/context';

//material ui
import MuiAlert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Snackbar from '@mui/material/Snackbar';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AppSnackbar = props => {
  const [ state, dispatch ] = useContext(AppContext);
  const classes = props.classes;

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch({type: 'app/ui/snackbar/close'});
  };

  var capitalizedSeverity = state.snackbarSeverity.charAt(0).toUpperCase() + state.snackbarSeverity.slice(1)

  return (
      <div>
        <Snackbar
          // sx={{ bottom: { xs: 90, sm: 0 } }}
          sx={{ display: 'flex', justifyContent: 'center', top: { xs: 60, sm: 0 }}}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={state.snackbarOpen}
          autoHideDuration={3000}
          onClose={handleClose}
          className={classes}
        >
          {(state.snackbarSeverity === 'success' || state.snackbarSeverity === 'info')
          ? <Alert
              onClose={handleClose}
              severity={state.snackbarSeverity}
              color="primary"
              sx={{ width: '100%' }}
            >
              <AlertTitle>
                {capitalizedSeverity}
              </AlertTitle>
              {state.snackbarMessage}
            </Alert>
          : <Alert
              onClose={handleClose}
              severity={state.snackbarSeverity}
              sx={{ width: '100%'}}
            >
              <AlertTitle>
                {capitalizedSeverity}
              </AlertTitle>
              {state.snackbarMessage}
            </Alert>
          }
        </Snackbar>
      </div>
  );
}

export default AppSnackbar;
