import React, { useEffect, useContext, useState, useRef, useLayoutEffect } from 'react';

// Container
import DataImport from './containers/DataImport';
import { ThemeController } from './containers/ThemeController';
import ndefReader from "./containers/ndefReader";
import { useNdefData } from './containers/useNdefData';
import { getOriginUrl,
        checkUrlParameter,
        getOs,
        isPwa,
        isLocalhost
      } from "./containers/Utils"

// Components
import AppBarTop from './components/AppBar'
import ErrorDialog from './components/ErrorDialog'
import OpenOnOtherDeviceDialog from './components/AppLinkDialog'
import AppSettingsDialog from './components/AppSettingsDialog';
import AppAboutAppDialog from './components/AppAboutAppDialog';
import AppHelpDialog from './components/AppHelpDialog';
import AppImprintDialog from './components/AppImprintDialog';
import DataPage from './components/DataPage'
// Reducer
import { AppContext } from './contexts/context'

// Material UI
import { makeStyles } from '@mui/styles';
import { SwipeableDrawer } from '@mui/material';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import { Box } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import { useGetLogo } from './components/AppUtils';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
// Resources
import packageInfo from '../package.json';

const ndefCtrl = new ndefReader();


const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
    backgroundColor: '#f4f4f6'
  },
  card: {
    margin: 20,
    paddingBottom: 0,
  },
  banner: {
    marginLeft: 20,
    marginRight: 20,
  },
  appBar: {
    position: 'fixed',
    top: 'auto',
    bottom: 0,
  },
  botNav: {
    color: "green",
    paddingTop: 12,
    paddingBottom: 16
  },
  grow: {
    flexGrow: 1,
  },
  drawerPaper: {
    borderRadius: '0 20px 20px 0',
  },
}));

function App({swCallback}) {
  const classes = useStyles();
  const [ state, dispatch ] = useContext(AppContext);
  const [menuTitle, setMenuTitle] = useState('Home');
  const [mainDrawer, setMainDrawer] = useState(false)
  const theme = useTheme()
  var logo = useGetLogo();

  const mainDrawerAnchor = 'left'

  localStorage.setItem("appVersion", packageInfo.version)

  const firstUpdateRef = useRef(true);
  const nfcPersmissionCheck = useRef(true);

  // ### NDEF Control
  const useNdef = useNdefData()
  const handleNDEFReadRef = useRef(state.ndefControl);
  var writeDataRef = useRef(useNdef.updateWriteData());

  const updateHandleNDEFReadRef = () => {
    handleNDEFReadRef.current = state.ndefControl;
  };

  const updateHandleWriteDataRef = () => {
    writeDataRef.current = useNdef.updateWriteData();
  }

  const scanDialogHandler = (message, data) => {
    switch (message) {
      case "readSuccessfullyDone":
      case "checkSkippedAndDone":
      // TODO(hoffmannm): trigger scan dialog here!?
      // Scan Dialog done triggered with readStatusHandler
        break;
      case "checkErrorEmptyTag":
        //Empty Tag found
        dispatch({type: 'app/ui/scanDialog/emptyTag'});
        break;
      case "checkWarningDifferentSn": //S03
        dispatch({type: 'app/ui/scanDialog/writingToSameDevice', value:{src: state.nfcTagSn, dest: data}});
        break;
      case 'checkDeviceIdFailed': //S04
        const dataSrc = state.nfcSchema.$id.split("/")[2]
        //In order not to bring in check and more Scandialog triggers here, I oonly set state in reducer to indicate states for errors so that the dialogies can use this to check for errors.
        dispatch({type: 'app/ui/scanDialog/writingToDiffDevice', value:{src: dataSrc, dest: data}});
        break;
      case 'checkVersionIdFailed': //S05
        dispatch({type: 'app/ui/scanDialog/writingMajorVersChanged', value:{srcVer: state.nfcSchema.$id.split("/")[3], destVer: data}});
        break;
      case 'jsonParseError': //S09
        dispatch({type: 'app/ui/scanDialog/jsonParseError'});
        break;
      case "checkErrorMediaTypeUnknown":
        dispatch({type: 'app/ui/scanDialog/unknownDevice', value: "RE01"});
        break;
      case "checkErrorRecordKeyUnknown":
        // Unknown record key found
        dispatch({type: 'app/ui/scanDialog/unknownDevice', value: "RE02"});
        break;
      case "checkErrorRecordEmpty":
        //Empty Record, tag technology or format not supported (NdefFormatable)
        dispatch({type: 'app/ui/scanDialog/unknownDevice', value: "RE03"});
        break;
      case "checkErrorRecordTypeUnkown":
        //Recordtype not supported
        dispatch({type: 'app/ui/scanDialog/unknownDevice', value: "RE04"});
        break;
      default:
        //unknown error or Recordtype not supported
        dispatch({type: 'app/ui/scanDialog/unknownDevice', value:"RE00"});
        break;
    }
  }

  const readStatusHandler = (status) => {
    switch (status) {
      case 'done':
        dispatch({type: 'ndef/scan/done'});
        break;
      case 'failed':
      default:
        dispatch({type: 'ndef/scan/failed'});
        dispatch({type: 'ndef/control/setForNewRead'});
        break;
    }
  }

   async function handleNDEFRead(ev) {
    console.info(`App | handleNDEFRead(): started`, handleNDEFReadRef.current)
    switch(handleNDEFReadRef.current) {
      default:
      case 'idle':
      case 'writing':
        console.info(`App | handleNDEFRead(): skipped`)
        break;
      case 'reading':
        useNdef.readingHandler(ev);
        console.info(`App | handleNDEFRead(): processed`)
        const reading_res=useNdef.readingHandler(ev);
        scanDialogHandler(reading_res.message, reading_res.data);
        readStatusHandler(reading_res.status);
        break;
    }
  }

  const writeStatusHandler = async (status, data) => {
    switch (status) {
      case 'tagOkay':
        dispatch({type: 'ndef/scan/processing'});
        try {
          console.info(`App | writeStatusHandler(): start write`);
          await ndefCtrl.write(data);
          dispatch({type: 'ndef/scan/done'});
        } catch (err) {
          dispatch({type: 'ndef/scan/failed'});
          // incorrect data structure or device detached while writing
          dispatch({type: 'app/ui/scanDialog/writeFailed'});
          console.error(`App | writeStatusHandler():`, err, data);
        } finally {
          console.info(`App | writeStatusHandler(): finished`);
        }
        break;
      case 'failed':
        console.error(`App | writeStatusHandler(): status`, status);
        dispatch({type: 'ndef/scan/failed'});
        break;
      default:
        break;
    }
  }

  async function handleNDEFWrite(ev) {
    console.info(`App | handleNDEFWrite() started`, ev.message)
    if (handleNDEFReadRef.current === 'writing') {
      const checkTag_res = useNdef.checkTag(ev)
      dispatch({
        type: `data/checkTag/done`,
        value: checkTag_res,
      });
    } else {
      console.info(`App | handleNDEFWrite() aborted`)
    }
  }

  useEffect(() => {
    if (state.checkTag === 'done' && handleNDEFReadRef.current === 'writing') {
      console.debug("App | useEffect[state.CheckTag] state.checkTagResult", state.checkTagResult)
      updateHandleWriteDataRef();
      scanDialogHandler(state.checkTagResult.message, state.checkTagResult.data);
      writeStatusHandler(state.checkTagResult.status, writeDataRef.current);
      console.info(`App | useEffect[state.CheckTag] writeDataRef.current#1`, writeDataRef.current)
    } else {
      console.warn(`App | handleNDEFWrite() aborted`)
      console.info(`App | useEffect[state.CheckTag]`, state.checkTag)
    }
    dispatch({type: 'ndef/control/writeUnlock'});
  }, [state.checkTag]);

  // Handle Read an Writes
  useEffect(() => {
    updateHandleNDEFReadRef();
    console.info(`App | ndefControl:`, state.ndefControl)
    console.info(`App | writeLock:`, state.writeLock)
    switch(state.ndefControl) {
      default:
      case 'idle':
      case 'reading':
        console.info(`App | ndefControl: writeAbort`)
        ndefCtrl.writeAbort();
        break;
      case 'writing':
        if (state.writeLock === false) {
          dispatch({type: 'ndef/control/writeLock'});
          ndefCtrl.checkBeforeWrite(handleNDEFWrite)
        } else {
          console.warn('App | ndefControl writing, but writeLock:', state.writeLock)
        }
        break;
    }
    return () => {
      // nothing to clean
    };
  }, [state.ndefControl]);

  React.useEffect(() => {
    swCallback.onSuccess = () => {
      console.debug('App | Service Worker - Init done');
      dispatch({
        type: 'app/sw/init'
      });
    };
    swCallback.onUpdate = (reg) => {
      console.debug('App | Service Worker - update waiting');
      dispatch({
        type: 'app/sw/update',
        value: reg
      });
      dispatch({
        type: 'app/ui/snackbar/show',
        message: "App was updated.",
        severity: 'info'
      });

    };
  }, []);

  const handleNDEFError = (ev) => {
    console.error(`App | handleReadError(): `, ev)
    dispatch({type: 'app/ui/scanDialog/unknownDevice', value: "RE99"});
  }

  useLayoutEffect(() => {
    if (firstUpdateRef.current) {
      console.log(`App | useLayoutEffect: first Update`);
      firstUpdateRef.current = false;
      dispatch({type: 'app/originUrlUpdated', value: getOriginUrl()});

      const subdomain = window.location.hostname.split('.').slice(0, -2).join('.');
      if (isLocalhost ||
          subdomain.includes('remote') ||
          subdomain.includes('dev') ||
          checkUrlParameter('ENV', 'dev'))
      {
        dispatch({type:"app/env/set", value:"dev"})
      } else if (subdomain.includes('stg') ||
                subdomain.includes('staging') ||
                checkUrlParameter('ENV', 'stg'))
      {
        dispatch({type:"app/env/set", value:"stg"})
      } else {
        dispatch({type:"app/env/set", value:"prd"})
      }

      if (!isPwa()) {
        console.debug(`isPWA: browser`)
        dispatch({type: 'app/status/displayMode', value: 'browser'});
      } else {
        console.debug(`isPWA: pwa`)
        dispatch({type: 'app/status/displayMode', value: 'pwa'});
      }

      if ("NDEFReader" in window) {
        dispatch({type: 'nfc/status/available', value: true});
        dispatch({type: 'app/ui/card/nfcNotAvailable', value: 'hide'});
        console.info("App | useEffect: NDEFReader available ...")
        ndefCtrl.scan(); // init scan
        ndefCtrl.read(handleNDEFRead);
        ndefCtrl.error(handleNDEFError);
      } else {
        console.warn("App | useEffect: NDEFReader not found.")
        dispatch({type: 'nfc/status/notAvailable'});
      }
      return;
    } else if (nfcPersmissionCheck.current) {
      nfcPersmissionCheck.current = false;
      if ("NDEFReader" in window) {
        checkPermission();
      }
      return;
    }
  });

  const checkPermission = async () => {
    const nfcPermissionStatus = await navigator.permissions.query({ name: "nfc" });
    console.info("nfcPermissionStatus.state", nfcPermissionStatus.state)
    dispatch({type: 'nfc/status/permission', value: nfcPermissionStatus.state});

    switch (nfcPermissionStatus.state) {
      case 'granted':
        dispatch({type: 'app/ui/card/nfcNotGranted', value: 'hide'});
        break;
      case 'denied':
      case 'prompt':
      default:
        if (state.uiCardNfcNotGranted !== 'dismissed' &&
            state.uiCardNfcNotAvailable !== 'show') {
          dispatch({type: 'app/ui/card/nfcNotGranted', value: 'show'});
        }
        break;
    }
  }

  useEffect(() => {
    if (getOs() !== "windows" &&
        state.uiCardAppInstallable === 'hide' &&
        state.appStatusDisplayMode === 'browser' &&
        state.appStatusInstallation === 'installed'
        ) {
      dispatch({type: 'app/ui/card/appOpen', value: 'show'});
    } else {
      dispatch({type: 'app/ui/card/appOpen', value: 'hide'});
    }
  }, [state.uiCardAppInstallable, state.appStatusDisplayMode, state.appStatusInstallation, dispatch]);

  useEffect(() => {
    if (state.nfcGrantCheck) {
      dispatch({type: 'app/ui/control/nfcGrantCheck', value: false});
      ndefCtrl.check().finally(() => {
        checkPermission();
      })
    }
  })

  useEffect(() => {
    console.info("App | ndefControl:", state.ndefControl)
    switch(state.ndefControl) {
      case 'idle':
        break;
      case 'reading':
        break;
      case 'writing':
        break;
      default:
        break;
    }

  }, [state.ndefControl]);

  let deferredPrompt;
  let [defPrompt, setDefPrompt] = useState(null)

  const displayInstallPrompt = async() => {
    console.debug(deferredPrompt)
      // Wait for the user to respond to the prompt
      defPrompt.prompt()
      defPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.debug('User accepted the install prompt');
          dispatch({type: 'app/status/installation/', value: 'progress'});
          dispatch({type: 'app/ui/card/appInstallable', value: 'hide'});
          setDefPrompt(null)
        } else {
          console.debug('User dismissed the install prompt');
        }
      });

      if(!defPrompt){
        dispatch({type: 'app/ui/card/appInstallable', value: 'hide'});
        console.debug("prompt is null")
        return;
      }
  }

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', (event)=>{
      // Prevent the mini-infobar from appearing on mobile
      event.preventDefault();
      // Stash the event so it can be triggered later.
      deferredPrompt = event;
      setDefPrompt(event)
      console.debug("beforeinstallprompt", event)
      console.debug("beforeinstallprompt", deferredPrompt)
      // Update UI notify the user they can install the PWA
      dispatch({type: 'app/ui/card/appInstallable', value: 'show'});
      dispatch({type: 'app/status/installation/', value: 'uninstalled'});
      localStorage.setItem("appStatusInstallation", "uninstalled");
    });

    window.addEventListener('appinstalled', (event) => {
      console.debug(event)
      console.debug('INSTALL TO HOMESCREEN: Success');
      dispatch({type: 'app/status/installation/', value: 'installed'});
      localStorage.setItem("appStatusInstallation", "installed");
    });

    if (localStorage.getItem("appStatusInstallation") === 'installed' &&
        state.appStatusInstallation !== 'installed') {
      dispatch({type: 'app/status/installation/', value: 'installed'});
    }

    if (state.appInstallPromptTrigger) {
      dispatch({type: 'app/ui/control/appInstallTrigger', value: false});
      displayInstallPrompt();
    }
  })

  const handleMenuClick=(data)=>{
    console.log(data)
    dispatch({type: 'app/ui/menu/dialog/goto', value: data});
    handleClose()
    console.log(state.uiMenuDialog)
  }

  const mainDrawerContent = () => {
    return(
      <Box
        sx={{ width:250, height:"100%", borderRadius: 5}}
      >
        <Box sx={{width:"85%", m:3, display:"flex", flexDirection:"row", alignContent:"center", justifyContent:"space-between"}}>
          <Box sx={{width:40, height:40, borderRadius:20, backgroundColor:theme.palette.primary.dark}}>
           <Avatar alt="Logo" src={logo} />
          </Box>
          <IconButton onClick={handleClose}>
            <KeyboardArrowLeftIcon />
          </IconButton>
        </Box>

        <Divider />
      <nav>
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={()=>handleMenuClick("settings")}>
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Settings" />
            </ListItemButton>
          </ListItem>
{/*           <ListItem disablePadding>
            <ListItemButton onClick={()=>handleMenuClick("about_app")}>
              <ListItemIcon>
                <InfoIcon />
              </ListItemIcon>
              <ListItemText primary="About App" />
            </ListItemButton>
          </ListItem> */}
          <ListItem disablePadding>
            <ListItemButton onClick={()=>handleMenuClick("help")}>
              <ListItemIcon>
                <TroubleshootIcon />
              </ListItemIcon>
              <ListItemText primary="Troubleshoot" />
            </ListItemButton>
          </ListItem>
        </List>
      </nav>
      <Box sx={{position:"absolute", bottom:20}}>
        <ListItem disablePadding>
            <ListItemButton onClick={()=>handleMenuClick("imprint")}>
              <ListItemIcon>
                <ContactSupportIcon />
              </ListItemIcon>
              <ListItemText primary="Imprint" />
            </ListItemButton>
          </ListItem>
      </Box>
      </Box>)
  };

  const toggleDrawer = (open) => {
    setMainDrawer(open);
  };

  const handleDrawerToggle=()=>{
    //console.log("Drawer toggle", true)
    toggleDrawer(true)
  }

  const handleClose = () => {
    setMainDrawer(false);
  };

  const handleOpen = () => {
    setMainDrawer(true);
  };

  console.debug("APP: Re-Render ...")

  return (
    /* Restructuring App UI */
    <div className={classes.root}>
    <ThemeController>
      <React.Fragment>
        <AppBarTop
          menu={menuTitle}
          onToggle={()=>handleDrawerToggle()}
        />
          <SwipeableDrawer
            anchor={mainDrawerAnchor}
            open={mainDrawer}
            variant={"temporary"}
            onOpen={()=>handleOpen()}
            onClose={()=>handleClose()}
            classes={{ paper: classes.drawerPaper }}
          >
             {mainDrawerContent()}

          </SwipeableDrawer>
          {state.uiMenuDialog==="settings" && <AppSettingsDialog/>}
          {state.uiMenuDialog==="about_app" && <AppAboutAppDialog/>}
          {state.uiMenuDialog==="help" && <AppHelpDialog/>}
          {state.uiMenuDialog==="imprint" && <AppImprintDialog/>}

        <ErrorDialog/>
        <OpenOnOtherDeviceDialog/>
        <DataImport/>

        <DataPage/>
      </React.Fragment>
    </ThemeController>
    </div>
  );
}

export default App;
