import React, { useState, useContext, useEffect } from 'react';
import { AppContext } from '../contexts/context';

// Material UI
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Toolbar from '@mui/material/Toolbar';
import Grow from '@mui/material/Grow';
import { Box } from '@mui/material';
import { ScanSlider } from './AppComponents';
import { ImageCard, AlertCard } from './scanDialogContents';
import { getBrandValue } from "../containers/Utils"

import {
  useCountDataKeys,
  useCountImportedValues
} from "../components/AppUtils";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow style={{ transformOrigin: '0 0 0 0' }}
  ref={ref} {...props} />;
});

const ScanDialog = props => {
  const [ state, dispatch ] = useContext(AppContext);
  const [open, setOpen] = useState(false);
  const [readState, setReadState] = React.useState(state.ndefControl==="reading"?false:true);
  const changedParams = useCountDataKeys();
  const importedParams = useCountImportedValues();

  console.debug("ScanDialog importedParams", importedParams)

  function checkForTagScannerCompatibility_S01() {
    //Check for Owner URLs after scan
    var brandValue = localStorage.getItem("brandValue");
    if (!brandValue) {
      console.warn(`ScanDialog | Failed loading brandValue from local storage! Using default value: kde`)
      brandValue = 'kde';
    }

    const brandValueDevice = getBrandValue(state.nfcUri);
    if(brandValue === 'kde'){
      // ignore Compatibility Check
      dispatch({type: 'app/ui/scanDialog/done', value: changedParams });
      if(state.ndefControl==="writing") {
        //Reset CountChangeKeys
        dispatch({type: 'ndef/scan/write/done'});
      }
      return "pass"
    }else{
      //proceed with check
      if(brandValue !== brandValueDevice) {
        console.debug("ScanDialog | brandValue", brandValue);
        console.debug("ScanDialog | brandValueDevice", brandValueDevice);
        return "failed"
      }else{
        return "pass"
      }
    }
  }

  const handleChange = (event) => {

    if (event===false || event ===true) {
    console.info("ScanDialog | handleChange:", event)
    //Event is either ReadMode or WriteMode
    setReadState(event)
      if (event===false) {
        dispatch({type: 'ndef/control/reading'});
        dispatch({type: 'app/ui/scanDialog/readInitializer', value: {scanTime:state.nfcDataScanTime, changedParams:changedParams}});
      } else {
        dispatch({type: 'ndef/control/writing'});
        dispatch({type:"app/ui/scanDialog/writeInitializer", value: changedParams})
      }
    }
  };

  useEffect(() => {
    if (state.uiScanDialog) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [state.uiScanDialog]);

  useEffect(() => {
    console.debug("ScanDialog | Check against new read with changed parameters");
    switch(state.nfcDataSource) {
      case 'unkown':
      case 'app':
      case 'scan':
        if (state.nfcDataValidationError === true) {
          console.warn("ScanDialog | nfcDataValidationError");
          dispatch({type: 'app/ui/scanDialog/writingChangedParamValidation'});
          return "failed"
        } else if( changedParams > 0 || state.nfcDataScanTime !== "unknown") {
          //Enable Write
          console.warn("ScanDialog | Enable Write");
          dispatch({type: 'ndef/control/writing'});
          dispatch({type: 'app/ui/scanDialog/writeInitializer', value: changedParams})
        }
        break;
      case 'import':
        //Enable Write
        console.warn("ScanDialog | Enable Write");
        dispatch({type: 'ndef/control/writing'});
        dispatch({type: 'app/ui/scanDialog/writeInitializer', value: importedParams})
        break;
      default:
        break;
    }
  }, []);

  useEffect(() => {
    switch (state.ndefControl) {
      case "reading":
        setReadState(false);
        console.debug('state.ndefControl === reading')
        if (state.uiNdefState !== "error") {
          dispatch({
            type: 'app/ui/scanDialog/readInitializer',
            value: {
              scanTime:state.nfcDataScanTime,
              changedParams:changedParams
            }
          });
        }
        break;
      case "writing":
        setReadState(true);
        if (state.uiNdefState !== "error") {
          dispatch({
            type: 'app/ui/scanDialog/writeInitializer',
            value: changedParams
          });
        }
        break;
      default:
        setReadState(false);
        break;
    }

    switch (state.uiNdefState) {
      case "scanning":
        switch(state.nfcDataSource) {
          case 'unkown':
          case 'app':
          case 'scan':
            if (state.nfcDataValidationError === true) {
              dispatch({
                type: 'app/ui/scanDialog/writingChangedParamValidation'
              });
              break;
            }
            if (state.ndefControl === "writing") {
              dispatch({
                type: 'app/ui/scanDialog/writeInitializer',
                value: changedParams
              });
            }
            break;
          case 'import':
            if (state.ndefControl === "writing") {
              dispatch({
                type: 'app/ui/scanDialog/writeInitializer',
                value: importedParams
              });
            }
            break;
          default:
            break;
        }
        if (state.ndefControl === "reading") {
          console.debug('state.ndefControl === reading')
          dispatch({
            type: 'app/ui/scanDialog/readInitializer',
            value: {scanTime:state.nfcDataScanTime, changedParams: changedParams}
          });
        }
        break;
      case "processing":
        dispatch({type: 'app/ui/scanDialog/processing'});
        break;
      case "done":
        console.debug("ScanDialog | uiNdefState is done");

        if (checkForTagScannerCompatibility_S01() === "failed") {
          // Do nothing
          dispatch({type: 'app/ui/scanDialog/incompatibleDevice'});
          dispatch({type: 'ndef/control/setForNewRead'});
          break;
        }

        dispatch({type: 'app/ui/scanDialog/done', value: changedParams });
        if (state.ndefControl === "writing") {
          //Reset CountChangeKeys
          dispatch({type: 'ndef/scan/write/done'});
        }
        break;
      case "error":
        break
      default:
        dispatch({type: 'app/ui/scanDialog/unknownError', value:"SE01"});
        dispatch({type: 'app/ui/scanDialog/invalid'});
        break;
    }
  }, [state.uiNdefState, state.ndefControl, state.nfcDataScanTime]);

  return (
    <Box sx={{display:"flex", flexDirection:"column", padding:10, overflow:"hidden"}}>
      <Dialog fullScreen TransitionComponent={Transition} onClose={props.abortHandler} aria-labelledby="form-dialog-read-scanning" open={open} sx={{maxWidth:"100%", overflowX:"hidden"}}>
        <Box sx={{ position: 'relative', backgroundColor:"#fff", mb:1 }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="close"
              onClick={props.abortHandler}
            >
              <CloseIcon sx={{color:'#3b3b3b'}}/>
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1, color:"#3b3b3b" }} variant="h6" component="div">
              Scan
            </Typography>
          </Toolbar>
        </Box>
        <DialogContent sx={{display:"flex", flexDirection:"column", paddingY:1, alignItems:"center", justifyContent:"center",}}>

           <ImageCard/>
           <AlertCard/>

        </DialogContent>
        <DialogActions sx={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", marginX:3}}>
          {state.nfcDataScanTime !== "unknown" && state.scanDialog_showModeChanger === true &&
             <ScanSlider
                checked={readState}
                onChange={(e)=>handleChange(e)}
                name="ndefControl"
                // writeDisabled={(Object.keys(state.nfcSchema).length <= 0)?true:false}
                writeDisabled={false}
                disabled={false}
              />
          }
        </DialogActions>
        <DialogActions sx={{display:"flex", marginX:3, paddingY:2, marginBottom:"16px", alignItems:"center", justifyContent:"left"}}>
          <Button autoFocus onClick={props.abortHandler} color="primary" variant="contained" size='large' sx={{justifySelf:"left", borderRadius:10}} >
            {state.scanDialog_dialogBtnText}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ScanDialog;
