// Context
import React, { useMemo, useState, useEffect, useRef, useContext, useLayoutEffect } from 'react';

// Context
import { AppContext } from '../contexts/context';

// Material UI

import { createTheme, ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';

// Firebase
import { storage } from "./Firebase"
import { ref, getDownloadURL, listAll } from "firebase/storage";

const useStyles = makeStyles((theme) => ({
  loading: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: '-50px',
    marginLeft: '-100px',
    width: '200px',
    height: '100px',
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
}));

// https://css-tricks.com/snippets/javascript/lighten-darken-color/
function LightenDarkenColor(col, amt) {

  var usePound = false;

  if (col[0] === "#") {
      col = col.slice(1);
      usePound = true;
  }

  var num = parseInt(col,16);

  var r = (num >> 16) + amt;

  if (r > 255) r = 255;
  else if  (r < 0) r = 0;

  var b = ((num >> 8) & 0x00FF) + amt;

  if (b > 255) b = 255;
  else if  (b < 0) b = 0;

  var g = (num & 0x0000FF) + amt;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (usePound?"#":"") + (g | (b << 8) | (r << 16)).toString(16);
}

export const ThemeController = ({ children }) => {
  const classes = useStyles();
  const [ state, dispatch ] = useContext(AppContext);
  const [ loading, setLoading ] = useState(true);
  const [ serviceWorkerFinished, setServiceWorkerFinished ] = useState(false);
  const [ manifestLoaded, setManifestLoaded ] = useState(false);
  const [ manifestLink, setManifestLink ] = useState('/kde.webmanifest');
  const [ loadingState, setLoadingState ] = useState('...');

  var brandValue = localStorage.getItem("brandValue");
  if (!brandValue) {
    console.warn(`ThemeController | Failed loading brandValue from local storage! Using default value: kde`)
    brandValue = 'kde';
  } else {
    console.warn(`ThemeController | brandValue:`, brandValue)
  }

  // Firebase storage reference
  const firstUpdateRef = useRef(true);
  const storageRef = ref(storage);
  var brandDeviceList = [];
  // var brandSchemaList = [];

  async function fetchSchemaData(url) {
    const response = await fetch(url);

    try {
      const result = await response.json();
      console.debug("fetchSchemaData | Schema downloaded ... ");
      console.debug("fetchSchemaData | Schema:", result);
      console.debug("fetchSchemaData | Schema as JSON:", JSON.stringify(result));
      // dispatch({type: 'data/S/updated', value: result});
    } catch (error) {
      console.error('fetchSchemaData | Failed:', error);
    }
  }

  function downloadSchemas(_schemasRef) {
    listAll(ref(storage, _schemasRef))
    .then((res) => {
        console.debug('ThemeController | Firebase: listAll', _schemasRef)
        res.prefixes.forEach((folderRef) => {
          console.debug('ThemeController | Firebase: Add device path', folderRef)
          brandDeviceList.push(folderRef._location.path_);
        });
      }).then((res) => {
        if (brandDeviceList !== null && brandDeviceList.length > 0) {
          // Get all shemas for every brand device
          brandDeviceList.forEach(device => {
            console.debug('ThemeController | Firebase: Get shemas for ', device);

            listAll(ref(storage, device)).then((res) => {
              res.prefixes.forEach((schemaRef) => {
                console.debug(`ThemeController | Firebase: ${schemaRef._location.path_}/schema.json`)
                // TODO Process directly instead of storing in array?
                // brandSchemaList.push(schemaRef._location.path_);
                try {
                  getDownloadURL(ref(storageRef, `${schemaRef._location.path_}/schema.json`))
                  .then((url) => {
                    console.log("ThemeController | DataImport URL:", url)
                    dispatch({type: 'app/status/online', value: true});
                    fetchSchemaData(url);
                  })
                  .catch((data) => {
                    // TODO: Maybe not offline, only data can be missing here!
                    dispatch({
                      type: 'app/ui/snackbar/show',
                      message: "Download URL not found for import!",
                      severity: 'error'
                    });
                    console.error("ThemeController | Download data: ", data)
                  });
                } catch (error) {
                  console.error('ThemeController | Error getDownloadURL', error);
                  dispatch({
                    type: 'app/ui/snackbar/show',
                    message: "Download URL not found for import!",
                    severity: 'error'
                  });
                }

              });
            }).catch((error) => {
              //  Device offline?
              console.error('ThemeController | Error listAll schemas', error);
              dispatch({
                type: 'app/ui/snackbar/show',
                message: "No connection for import!",
                severity: 'error'
              });
            })
          });
        }
      }).catch((error) => {
        // Device offline?
        console.error('ThemeController | Error listAll devices', error);
        dispatch({
          type: 'app/ui/snackbar/show',
          message: "No connection for import!",
          severity: 'error'
        });
      });
      brandDeviceList = [];
  }

  useLayoutEffect(() => {
    if (firstUpdateRef.current) {
      firstUpdateRef.current = false;
      console.debug('ThemeController | Firebase - connecting ...')
      // Find all devices from the brand.
      var _schemasRef = `brands/${brandValue}/schemas`;

      if (brandValue === 'kde') {
        console.debug('ThemeController | Firebase: KDE JAA! ');
        listAll(ref(storage, `brands`))
        .then((res) => {
          res.prefixes.forEach((folderRef) => {
            console.debug('ThemeController | Firebase: Try to download:', folderRef._location.path_);
            downloadSchemas(`${folderRef._location.path_}/schemas`);
            // brandsList.push(folderRef._location.path_);
          });
        }).catch((error) => {
          // Device offline?
          console.error('ThemeController | Error listAll brands', error);
          dispatch({
            type: 'app/ui/snackbar/show',
            message: "No connection for import!",
            severity: 'error'
          });
        });
      } else {
        console.debug('ThemeController | Firebase: KDE NEIN! ');
        downloadSchemas(_schemasRef);
      }
    }
  });



  var primaryLight = LightenDarkenColor(state.brandColor1, -40);
  var primaryDark = LightenDarkenColor(state.brandColor1, 40);
  var secondaryLight = LightenDarkenColor(state.brandColor2, -40);
  var secondaryDark = LightenDarkenColor(state.brandColor2, 40);

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          primary: {
            light: primaryLight,
            main: state.brandColor1,
            dark: primaryDark
          },
          secondary: {
            light: secondaryLight,
            main: state.brandColor2,
            dark: secondaryDark
          },
        },
        overrides: {
          MuiCard: {
            root: {
              marginLeft: 10,
              marginRight: 10,
              marginTop: 10,
              marginBottom: 0,
            }
          },
        },
        components: {
          MuiFormControl: {
            styleOverrides: {
              root: {
                marginBottom: 15,
              },
            }
          },
          MuiInputLabel: {
            styleOverrides: {
              root: {
                fontWeight: 500,
              },
            }
          },
          MuiFormHelperText: {
            styleOverrides: {
              root: {
                fontStyle: 'italic'
              }
            }
          }
        },
        spacing: 8,
      }),
    [state.brandColor1, state.brandColor2],
  );

  const appCycleCheck = () => {

    if ("serviceWorker" in navigator && process.env.NODE_ENV === 'production') {
      setLoadingState("Service Worker ...");
      navigator.serviceWorker.ready.then((registration) => {
        console.log(`ThemeController | appCycleCheck: A service worker is active:`, registration.active);
        setServiceWorkerFinished(true);
        // At this point, you can call methods that require an active
        // service worker, like registration.pushManager.subscribe()
      });
    } else {
      console.warn("ThemeController | appCycleCheck: Service workers are not supported.");
      setLoadingState("Service Worker skipped!");
      setServiceWorkerFinished(true);
    }

    // var manifestSelection = document.querySelector('#manifest-selection');
    window.addEventListener('load', function() {
      console.info("ThemeController | appCycleCheck: everything is finished");
      setManifestLoaded(true);
    });
  }

  const getManifestData=(href)=>{
    fetch(href, {
      headers : {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
       }
    }).then(function(response){
      return response.json();
    }).then(function(myJson) {
      console.log('ThemeController | manifestData:', myJson);
      dispatch({
        type: 'app/brand/manifest/updated',
        appName: myJson.name,
        appNameShort: myJson.short_name
      });
      console.debug("ThemeController | loading done")
      setLoading(false);
    });
  }

  const getBrandData=(href)=>{
    fetch(href, {
      headers : {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
       }
    }).then(function(response){
      return response.json();
    }).then(function(myJson) {

      let brandData = myJson.brands[brandValue];
      console.log('ThemeController | brandData:', brandData);
      console.log('ThemeController | brandData:', brandData.Name);

      dispatch({
        type: 'app/brand/data/updated',
        brandName: brandData.Name,
        brandTitle: brandData.Title,
        brandColor1: brandData.Color1,
        brandColor2: brandData.Color2,
        brandOwner: brandData.Owner,
        brandStreet: brandData.Street,
        brandZip: brandData.ZipCode,
        brandCity: brandData.City,
        brandMail: brandData.Mail,
        brandPhone: brandData.Phone
      });
    });
  }

  useEffect(() => {
    appCycleCheck()
    if (serviceWorkerFinished) {
      setLoadingState("Theme ...");
      const href = `/appBrands.json`
      console.debug('ThemeController | brandValue:', brandValue);
      if (brandValue) {
        dispatch({type: 'app/brand/logoUrl/updated', value: `image/${brandValue}/logo.png`});
        setManifestLink(`/${brandValue}.webmanifest`);
      }
      getBrandData(href);

      if (manifestLoaded) {
        if (manifestLink !== 'notdefined') {
          console.log("ThemeController | manifestLink:", manifestLink);
          getManifestData(manifestLink);
        } else {
          console.error('No href for webmanifest found!')
        }
      }
    }
  }, [manifestLink, manifestLoaded, serviceWorkerFinished]);

  if (loading === true) {
    return (
      <ThemeProvider theme={theme}>
        <div className={classes.loading}>
          <CircularProgress color="secondary" />
          <Typography sx={{
            marginTop: 2,
          }}>
            Loading {loadingState}
          </Typography>
        </div>
      </ThemeProvider>
    );
  } else {
    return (
      <ThemeProvider theme={theme}>
        <>
          { children }
        </>
      </ThemeProvider>
    );
  }
}

