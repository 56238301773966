import { getTimeString } from '../components/AppUtils';

function writeInitializerType (state) {
  if (state.settingForceWrite || state.settingSeamlessWrite) {
    return "warning"
  } else {
    return "info"
  }
}

function writeInitializerHeadline (state) {
  if (state.settingForceWrite || state.settingSeamlessWrite) {
    return "Warning"
  } else {
    return "Information"
  }
}

function writeInitializerSubHeadline (state) {
  if (state.settingForceWrite) {
    return "Froce write mode enabled"
  } else if (state.settingSeamlessWrite) {
    return "Seamless write mode enabled"
  } else {
    return  "Write mode enabled"
  }
}

function writeInitializerText (state, value) {
  if (state.settingForceWrite) {
    return "Data is written to every device without checking."
  } else if (state.settingSeamlessWrite) {
    return "Writing to other devices of the same type allowed."
  } else {
    return (value <= 0)? "Data will be written to scanned device.":`${value} change(s) will be written to device.`
  }
}


export const reducer = (state, action) => {
  let timeString = getTimeString();
  switch (action.type) {
    case 'ndef/control/idle':
      console.debug("Dispatch:", action.type)
      return {
        ...state,
        ndefControl: 'idle',
      };
    case 'ndef/control/reading':
      console.debug("Dispatch:", action.type)
      return {
        ...state,
        ndefControl: 'reading',
      };
    case 'ndef/control/writing':
      console.debug("Dispatch:", action.type)
      return {
        ...state,
        ndefControl: 'writing',
      };
    case 'ndef/control/writeLock':
      console.debug("Dispatch:", action.type)
      return {
        ...state,
        writeLock: true,
      };
    case 'ndef/control/writeUnlock':
      console.debug("Dispatch:", action.type)
      return {
        ...state,
        writeLock: false,
      };
    case 'ndef/control/updateScanTime':
      console.debug("Dispatch:", action.type)
      return {
        ...state,
        nfcDataScanTime: timeString,
      };
    case 'ndef/control/setForNewRead':
      console.debug("Dispatch:", action.type)
      return {
        ...state,
        nfcSchema: {},
        nfcDataV: {},
        nfcDataVP: {},
        nfcDataP: {},
        nfcDataPR: {},
        nfcDataPP: {},
        originP: {},
        originPR: {},
        originPP: {},
        nfcDataCP: {},
        nfcAvailable: true,
        nfcDataScanTime:"unknown"
      };
    case 'ndef/control/resetChanges':
      console.debug("Dispatch:", action.type)
      return {
        ...state,
        nfcDataP: state.originP,
        nfcDataPR: state.originPR,
        nfcDataPP: state.originPP,
      };
    case 'ndef/scan/write/done':
      console.debug("Dispatch:", action.type)
      return {
        ...state,
        originP: state.nfcDataP,
        originPR: state.nfcDataPR,
        originPP: state.nfcDataPP,
      };
    case 'ndef/scan/scanning':
      console.debug("Dispatch:", action.type)
      return {
        ...state,
        uiNdefState: 'scanning',
      };
    case 'ndef/scan/processing':
      console.debug("Dispatch:", action.type)
      return {
        ...state,
        uiNdefState: 'processing',
      };
    case 'ndef/scan/failed':
      console.debug("Dispatch:", action.type)
      return {
        ...state,
        uiNdefState: 'error',
      };
    case 'ndef/scan/done':
      console.debug("Dispatch:", action.type)
      return {
        ...state,
        uiNdefState: 'done',
        nfcDataScanTime: timeString,
      };
    case 'nfc/status/available':
      console.debug("Dispatch:", action.type)
      return {
        ...state,
        nfcAvailable: action.value,
      };
    case 'nfc/status/permission':
      console.debug("Dispatch:", action.type)
      return {
        ...state,
        nfcPermission: action.value,
      };
    case 'nfc/status/notAvailable':
      console.debug("Dispatch:", action.type)
      return {
        ...state,
        nfcAvailable: false,
      };
    case 'data/nfcTagSnUpdated':
      console.debug("Dispatch:", action.type)
      return {
        ...state,
        nfcTagSn: action.value,
        nfcDataSource: 'scan'
      };
    case 'app/originUrlUpdated':
      console.debug("Dispatch:", action.type)
      return { ...state, currentURL: action.value };
    case 'data/V/updated':
      console.debug("Dispatch:", action.type, action.value)
      return {
        ...state,
        nfcDataV: action.value
      };
    case 'data/VP/updated':
      console.debug("Dispatch:", action.type, action.value)
      return {
        ...state,
        nfcDataVP: action.value
      };
    case 'data/P/readOut':
      console.debug("Dispatch:", action.type)
      return {
        ...state,
        nfcDataP: action.value,
        originP: action.value
      };
    case 'data/PR/readOut':
      console.debug("Dispatch:", action.type)
      return {
        ...state,
        nfcDataPR: action.value,
        originPR: action.value
      };
    case 'data/PP/readOut':
      console.debug("Dispatch:", action.type)
      return { ...state, nfcDataPP: action.value, originPP: action.value };
    case 'data/P/updated':
      console.debug("Dispatch:", action.type)
      return {
        ...state,
        nfcDataP: action.value,
      };
    case 'data/PR/updated':
      console.debug("Dispatch:", action.type)
      return {
        ...state,
        nfcDataPR: action.value,
      };
    case 'data/PP/updated':
      console.debug("Dispatch:", action.type)
      return {
        ...state,
        nfcDataPP: action.value,
      };
    case 'data/CP/updated':
      console.debug("Dispatch:", action.type)
      return {
        ...state,
        nfcDataCP: action.value
      };
    case 'data/checkTag/setStatus':
      console.debug("Dispatch:", action.type)
      return {
        ...state,
        checkTag: action.value
      };
    case 'data/checkTag/done':
      console.debug("Dispatch:", action.type)
      return {
        ...state,
        nfcUri: action.value.nfcUri,
        nfcSchemaCompressed: action.value.nfcSchemaCompressed,
        nfcDataCP: action.value.nfcDataCP,
        nfcDataP: action.value.nfcDataP,
        // originP: action.value.nfcDataP,
        nfcDataPP: action.value.nfcDataPP,
        // originPP: action.value.nfcDataPP,
        nfcDataPR: action.value.nfcDataPR,
        // originPR: action.value.nfcDataPR,
        nfcDataV: action.value.nfcDataV,
        nfcDataVP: action.value.nfcDataVP,
        checkTagResult: action.value,
        checkTag: 'done'
      };
    case 'data/urlUpdated':
      console.debug("Dispatch:", action.type)
      return { ...state, nfcUri: action.value };
    case 'data/validError':
      console.debug("Dispatch:", action.type)
      return { ...state, nfcDataValidationError: action.value };
    case 'data/S/updated':
      console.debug("Dispatch:", action.type)
      return {
        ...state,
        nfcSchema: action.value,
      };
    case 'data/S/updatedCompressed':
      console.debug("Dispatch:", action.type)
      return {
        ...state,
        nfcSchemaCompressed: action.value
      };
    case 'data/source/set':
      console.debug("Dispatch:", action.type)
      return { ...state,
        nfcDataSource: action.value
      };
    // ## Application User Interface
    case 'app/ui/card/nfcNotAvailable':
      console.debug("Dispatch:", action.type)
      return { ...state, uiCardNfcNotAvailable: action.value };
    case 'app/ui/card/nfcNotGranted':
      console.debug("Dispatch:", action.type)
      return { ...state, uiCardNfcNotGranted: action.value };
    case 'app/ui/card/appInstallable':
      console.debug("Dispatch:", action.type)
      return { ...state, uiCardAppInstallable: action.value };
    case 'app/ui/card/appOpen':
      console.debug("Dispatch:", action.type)
      return { ...state, uiCardAppOpen: action.value };
    case 'app/ui/dialog/ndef/show':
      console.debug("Dispatch:", action.type)
      return { ...state, uiScanDialog: action.value };
    case 'app/ui/dialog/default/show':
      console.debug("Dispatch:", action.type)
      return { ...state, uiDialog: action.value };
    case 'app/ui/dialog/default/close':
      console.debug("Dispatch:", action.type)
      return { ...state, uiDialog: null };
    case 'app/ui/menu/dialog/default/close':
      console.debug("Dispatch:", action.type)
      return { ...state, uiMenuDialog: null };
    case 'app/ui/menu/dialog/goto':
      console.debug("Dispatch:", action.type)
      return { ...state, uiMenuDialog: action.value };
    case 'app/ui/dataPage/tab':
      console.debug("Dispatch:", action.type)
      return { ...state, uiDataPageTab: action.value };
    case 'app/ui/control/nfcGrantCheck':
      console.debug("Dispatch:", action.type)
      return {
        ...state,
        nfcGrantCheck: action.value,
      };
    case 'app/ui/control/appInstallTrigger':
      console.debug("Dispatch:", action.type)
      return {
        ...state,
        appInstallPromptTrigger: action.value,
      };
    case 'app/ui/dialog/error':
      console.debug("Dispatch:", action.type)
      return {
        ...state,
        errorTitle: action.title,
        errorText: action.text
      };
    case 'app/ui/snackbar/show':
      console.debug("Dispatch:", action.type)
      return { ...state,
        snackbarOpen: true,
        snackbarMessage: action.message,
        snackbarSeverity: action.severity
      };
    case 'app/ui/snackbar/close':
      console.debug("Dispatch:", action.type)
      return { ...state,
        snackbarOpen: false,
        snackbarMessage: null
      };
    // ### Scan Dialogue Reducers
    case 'app/ui/scanDialog/readInitializer':
      console.debug("Dispatch:", action.type)
      return { ...state,
        scanDialog_alertType:(action.value.changedParams === 0)?"info":"warning",
        scanDialog_alertHeadline: "Information",
        scanDialog_alertSubHeadline: "Read mode enabled",
        scanDialog_alertText: (action.value.scanTime!=="unknown" || action.value.changedParams > 0)?`Current data ${(action.value.changedParams>0)?('with '+action.value.changedParams+' change(s)'):''} will be discarded.`:"Read data from a device first.",
        scanDialog_ImageCardType:"start", //image["start", "progress", "success", "error"]
        scanDialog_ImageCardHeadline:"Ready to scan",
        scanDialog_ImageCardSubtext:"To scan, hold your phone close to the device.",
        scanDialog_showModeChanger:(action.value.scanTime==="unknown")?false:true,
        scanDialog_dialogBtnText:"Cancel"
      };
    case 'app/ui/scanDialog/writeInitializer':
      console.debug("Dispatch:", action.type)
      return { ...state,
        scanDialog_alertType: writeInitializerType(state),
        scanDialog_alertHeadline: writeInitializerHeadline(state),
        scanDialog_alertSubHeadline: writeInitializerSubHeadline(state),
        scanDialog_alertText: writeInitializerText(state, action.value),
        // scanDialog_alertText:(action.value <= 0)? "Data will be written to scanned device.":`${action.value} change(s) will be written to device.`,
        scanDialog_ImageCardType:"start", //image["start", "progress", "success", "error"]
        scanDialog_ImageCardHeadline:"Ready to scan",
        scanDialog_ImageCardSubtext:"To scan, hold your phone close to the device.",
        scanDialog_showModeChanger:true,
        scanDialog_dialogBtnText:"Cancel"
      };
    // Device was scanned with wrong brand value version.
    case 'app/ui/scanDialog/incompatibleDevice':
      console.debug("Dispatch:", action.type)
      navigator.vibrate([200, 30, 200, 30, 200]);
      return { ...state,
        scanDialog_alertType:"error",
        scanDialog_alertHeadline:`Error S01`,
        scanDialog_alertSubHeadline: "Device not compatible",
        scanDialog_alertText: "The device is not compatible with this app.",
        scanDialog_ImageCardType:"error", //image["start", "progress", "success", "error"]
        scanDialog_ImageCardHeadline:"Scan failed",
        scanDialog_ImageCardSubtext:"Please scan a different device.",
        scanDialog_showModeChanger:false,
        scanDialog_dialogBtnText:"Cancel"
      };
    // RE00 - General read / check error
    // RE01 - Wrong record media type
    // RE02 - Record key unkown
    // RE03 - Empty record, tag technology or format not supported (NdefFormatable)
    // RE04 - Record type not supported
    case 'app/ui/scanDialog/unknownDevice':
      console.debug("Dispatch:", action.type, action.value)
      navigator.vibrate([200, 30, 200, 30, 200]);
      return { ...state,
        scanDialog_alertType:"error",
        scanDialog_alertHeadline:`Error S02-${action.value}`,
        scanDialog_alertSubHeadline: `Unknown or faulty device detected`,
        scanDialog_alertText: "The device is either faulty or not compatible with this app.",
        scanDialog_ImageCardType:"error", //image["start", "progress", "success", "error"]
        scanDialog_ImageCardHeadline:"Scan failed",
        scanDialog_ImageCardSubtext:"Please try again or scan another device.",
        scanDialog_showModeChanger:false,
        scanDialog_dialogBtnText:"Cancel"
      };
    // Sn check failed while writing
    case 'app/ui/scanDialog/writingToSameDevice':
      console.debug("Dispatch:", action.type)
      navigator.vibrate([200, 30, 200, 30, 200]);
      return { ...state,
        scanDialog_alertType:"error",
        scanDialog_alertHeadline:`Error S03`,
        scanDialog_alertSubHeadline: "Other device recognised by serial number",
        scanDialog_alertText: `The first read is made via no. ${action.value.src.substring(0, 5)}, but the write attempt is made on no. ${action.value.dest.substring(0, 5)}.`,
        scanDialog_ImageCardType:"error", //image["start", "progress", "success", "error"]
        scanDialog_ImageCardHeadline:"Write attempt blocked",
        scanDialog_ImageCardSubtext:"Read device at first before writing or activate semless writing in settings menu.",
        scanDialog_showModeChanger:false,
        scanDialog_dialogBtnText:"Cancel"
      };
    // Device type (article code) check failed while writing
    case 'app/ui/scanDialog/writingToDiffDevice':
      console.debug("Dispatch:", action.type)
      navigator.vibrate([200, 30, 200, 30, 200]);
      return { ...state,
        scanDialog_alertType:"error",
        scanDialog_alertHeadline:`Error S04`,
        scanDialog_alertSubHeadline:"Different device detected",
        scanDialog_alertText: `No data can be written from device ${action.value.src} to device ${action.value.dest}.`,
        scanDialog_ImageCardType:"error", //image["start", "progress", "success", "error"]
        scanDialog_ImageCardHeadline:"Write attempt failed",
        scanDialog_ImageCardSubtext:`Please try again with ${action.value.src} or read from device ${action.value.dest} first.`,
        scanDialog_showModeChanger:false,
        scanDialog_dialogBtnText:"Cancel"
      };
    // Schema version is different
    case 'app/ui/scanDialog/writingMajorVersChanged':
      console.debug("Dispatch:", action.type)
      navigator.vibrate([200, 30, 200, 30, 200]);
      return { ...state,
        scanDialog_alertType:"error",
        scanDialog_alertHeadline:`Error S05`,
        scanDialog_alertSubHeadline: "Different configuration version detected",
        scanDialog_alertText: `Version ${action.value.srcVer} of the current configuration is not compatible with target version ${action.value.destVer}. `,
        scanDialog_ImageCardType:"error", //image["start", "progress", "success", "error"]
        scanDialog_ImageCardHeadline:"Write attempt failed",
        scanDialog_ImageCardSubtext:"Read this device at first before writing or try another device.",
        scanDialog_showModeChanger:false,
        scanDialog_dialogBtnText:"Cancel"
      };
    // Parameter validation failed
    case 'app/ui/scanDialog/writingChangedParamValidation':
      console.debug("Dispatch:", action.type)
      navigator.vibrate([200, 30, 200, 30, 200]);
      return { ...state,
        scanDialog_alertType:"error",
        scanDialog_alertHeadline:`Error S06`,
        scanDialog_alertSubHeadline: "Parameter validation failed",
        scanDialog_alertText: "Writing not possible. Please check and correct the form!",
        scanDialog_ImageCardType:"error", //image["start", "progress", "success", "error"]
        scanDialog_ImageCardHeadline:"Scan disabled",
        scanDialog_ImageCardSubtext:"Please check the current changes for validity.",
        scanDialog_showModeChanger:false,
        scanDialog_dialogBtnText:"Cancel"
      };
    // Empty tag (no records found)
    case 'app/ui/scanDialog/emptyTag':
      console.debug("Dispatch:", action.type)
      navigator.vibrate([200, 30, 200, 30, 200]);
      return { ...state,
        scanDialog_alertType:"error",
        scanDialog_alertHeadline:`Error S07`,
        scanDialog_alertSubHeadline: "Empty tag found",
        scanDialog_alertText: "No data available. Please wait a moment and try again.",
        scanDialog_ImageCardType:"error", //image["start", "progress", "success", "error"]
        scanDialog_ImageCardHeadline:"Scan failed",
        scanDialog_ImageCardSubtext:"Please try again or use another device.",
        scanDialog_showModeChanger:false,
        scanDialog_dialogBtnText:"Cancel"
      };
    // Write error
    case 'app/ui/scanDialog/writeFailed':
      console.debug("Dispatch:", action.type)
      navigator.vibrate([200, 30, 200, 30, 200]);
      return { ...state,
        scanDialog_alertType:"error",
        scanDialog_alertHeadline:`Error S08`,
        scanDialog_alertSubHeadline: "Transmission error",
        scanDialog_alertText: "Writing cancelled due to incorrect or incomplete data. Maybe your device has been detached from the tag.",
        scanDialog_ImageCardType:"error", //image["start", "progress", "success", "error"]
        scanDialog_ImageCardHeadline:"Write attempt failed",
        scanDialog_ImageCardSubtext:"Please try again and keep device intact during scan.",
        scanDialog_showModeChanger:false,
        scanDialog_dialogBtnText:"Cancel"
      };
    // Json format error
    case 'app/ui/scanDialog/jsonParseError':
      console.debug("Dispatch:", action.type)
      navigator.vibrate([200, 30, 200, 30, 200]);
      return { ...state,
        scanDialog_alertType:"error",
        scanDialog_alertHeadline:`Error S09`,
        scanDialog_alertSubHeadline: "Incorrect data",
        scanDialog_alertText: "Incorrect data found. Please wait a moment and try again. ",
        scanDialog_ImageCardType:"error", //image["start", "progress", "success", "error"]
        scanDialog_ImageCardHeadline:"Scan failed",
        scanDialog_ImageCardSubtext:"Please try again or use another device.",
        scanDialog_showModeChanger:false,
        scanDialog_dialogBtnText:"Cancel"
      };
    // Default error
    case 'app/ui/scanDialog/unknownError':
      console.debug("Dispatch:", action.type)
      navigator.vibrate([200, 30, 200, 30, 200]);
      return { ...state,
        scanDialog_alertType:"error",
        scanDialog_alertHeadline:`Error S99-${action.value}`,
        scanDialog_alertSubHeadline: `Unknown error`,
        scanDialog_alertText: "An unknown scan error occurred.",
        scanDialog_ImageCardType:"error", //image["start", "progress", "success", "error"]
        scanDialog_ImageCardHeadline:"Scan failed",
        scanDialog_ImageCardSubtext:"Please try again or scan another device.",
        scanDialog_showModeChanger:false,
        scanDialog_dialogBtnText:"Cancel"
      };
    case 'app/ui/scanDialog/processing':
      console.debug("Dispatch:", action.type)
      return { ...state,
        scanDialog_alertType:"processing",
        scanDialog_alertHeadline:"Information",
        scanDialog_alertSubHeadline: "Scan in progress",
        scanDialog_alertText: "Please wait ...",
        scanDialog_ImageCardType:"progress", //image["start", "progress", "success", "error"]
        scanDialog_ImageCardHeadline:"Transmitting data...",
        scanDialog_ImageCardSubtext:"Please hold still while transmitting.",
        scanDialog_showModeChanger:false,
        scanDialog_dialogBtnText:"Cancel"
      };
    case 'app/ui/scanDialog/done':
      console.debug("Dispatch:", action.type)
      navigator.vibrate([100, 30, 100]);
      return { ...state,
        scanDialog_alertType:"success",
        scanDialog_alertHeadline:"Scan status",
        scanDialog_alertSubHeadline: "Successful",
        scanDialog_alertText: (!action.value || action.value<=0)?"Data has been updated.":`${action.value} parameter(s) has been updated on the device.`,
        scanDialog_ImageCardType:"success", //image["start", "progress", "success", "error"]
        scanDialog_ImageCardHeadline:"Scan complete",
        scanDialog_ImageCardSubtext:"Data has been successfully transmitted.",
        scanDialog_showModeChanger:false,
        scanDialog_dialogBtnText:"Done",
      };
    // ## Application User Settings
    case 'app/settings/userAuthorized':
      console.debug("Dispatch:", action.type)
      return {
        ...state,
        settingAuthenticated: action.value
      };
    case 'app/settings/seamlessRead/toggle':
      console.debug("Dispatch:", action.type)
      return {
        ...state,
        settingSeamlessRead: action.value
      };
    case 'app/settings/seamlessWrite/toggle':
      console.debug("Dispatch:", action.type)
      return {
        ...state,
        settingSeamlessWrite: action.value
      };
    case 'app/settings/forceWrite/toggle':
      console.debug("Dispatch:", action.type)
      return {
        ...state,
        settingForceWrite: action.value
      };
    // ## Application Status
    case 'app/status/online':
      console.debug("Dispatch:", action.type, action.value)
      return {
        ...state,
        appOnline: action.value
      };
    case 'app/status/installation/':
      console.debug("Dispatch:", action.type, action.value)
      return {
        ...state,
        appStatusInstallation: action.value
      };
    case 'app/status/displayMode':
      console.debug("Dispatch:", action.type)
      return {
        ...state,
        appStatusDisplayMode: action.value,
      };
    // ## Application Theme by Brands
    case 'app/brand/logoUrl/updated':
      console.debug("Dispatch:", action.type, action.value)
      return { ...state, appBrandLogoUrl: action.value };
    case 'app/brand/data/updated':
      console.debug("Dispatch:", action.type)
      return { ...state,
        brandName: action.brandName,
        brandTitle: action.brandTitle,
        brandColor1: action.brandColor1,
        brandColor2: action.brandColor2,
        brandOwner: action.brandOwner,
        brandStreet: action.brandStreet,
        brandZip: action.brandZip,
        brandCity: action.brandCity,
        brandMail: action.brandMail,
        brandPhone: action.brandPhone
      };
    case 'app/brand/manifest/updated':
      console.debug("Dispatch:", action.type, action.appName, action.appNameShort)
      return { ...state,
        appManifestName: action.appName,
        appManifestShortName: action.appNameShort
      };
    case 'app/env/set':
      console.debug("Dispatch:", action.type, "Value: ", action.value)
      return { ...state,
        appEnv: action.value
      };
    case 'app/env/resetToNoData':
      console.debug("Dispatch:", action.type, "Value: ", action.value)
      return {
        ...state,
        uiNdefState: 'done',
        nfcDataScanTime: 'unknown'
      };
    case 'app/sw/init':
      console.debug("Dispatch:", action.type, "Value: ", action.value)
      return {
        ...state,
        appSwInitialized: true,
      };
    case 'app/sw/update':
      console.debug("Dispatch:", action.type, "Reg: ", action.value)
      return {
        ...state,
        appSwUpdated: true,
        appSwRegistration: action.value,
      };
    default: return state;
  }
}

// Todo: Make state nested with 'immer':
// https://www.npmjs.com/package/immer

export const initialState = {
  brandName: '', //
  brandTitle: '', // Sub title
  brandColor1: '#005d9d', // Primary Color
  brandColor2: '#00796b', // Secondary Color
  brandOwner: false, // Show Product Owner Info
  brandStreet: '',
  brandZip: '',
  brandCity: '',
  brandMail: '',
  brandPhone: '',
  appManifestName: 'NFC Configuration',
  appManifestShortName: 'NFC Config',
  appBrandLogoUrl: 'image/kde/logo.png',
  appOnline: true,
  currentURL: 'undefined',
  nfcTagSn: '00:00:00:00:00:00:00:00',
  nfcUri: null,
  nfcSchemaCompressed: null, // schema from device
  nfcSchema: {}, // unzipped or imported schema
  nfcDataValidationError: false,
  nfcDataScanTime: 'unknown',
  nfcDataSource: 'unknown', // [import / share, scan, app]
  // changed or imported values:
  nfcDataV: {},
  nfcDataVP: {},
  nfcDataP: {},
  nfcDataPR: {},
  nfcDataPP: {},
  nfcDataCP: {},
  // inital laoded values without changes:
  originP: {},
  originPR: {},
  originPP: {},
  checkTag: 'pending', // pending, done
  checkTagResult: {},
  nfcAvailable: false,
  nfcPermission: 'prompt', // [granted, denied, prompt]
  nfcGrantCheck: false,
  appInstallPromptTrigger: false,
  appStatusDisplayMode: 'undefined', // [browser, pwa, undefined]
  appStatusInstallation: 'undefined', // [undefined, uninstalled, progress, installed] Keeps record on if app is installed or not
  uiCardNfcNotAvailable: 'show',
  uiCardNfcNotGranted: 'hide',
  uiCardAppInstallable: 'hide',
  uiCardAppOpen: 'hide',
  uiDialog: null, // [share, devmode, import]
  uiMenuDialog: null, // [settings, about, help]
  uiDataPageTab: 0, // 0 = values, 1 = parameters
  uiScanDialog: false, // Show scanDialog or not´
  uiImportConfigName: 'unkown',
  ndefControl: 'idle', // [idle, reading, writing]
  uiNdefState: 'done', // [done, scanning, error, ...]
  writeLock: false,
  errorTitle: null,
  errorText: null,
  snackbarOpen: false,
  snackbarMessage: null,
  snackbarSeverity:'info', // success, info, warning, error
  settingAuthenticated: false,
  settingSeamlessRead: false, // [state to handle seamless read]
  settingSeamlessWrite: false, // [state to handle seamless write]
  settingForceWrite: false, // [state to handle seamless write]
  appEnv:'prd',
  // Service Worker
  appSwInitialized: false,
  appSwUpdated: false,
  appSwRegistration: null,
  //Dialogue States
  scanDialog_alertType:"", //alert type ["error", "info", "success", "warning", "processing"]
  scanDialog_alertHeadline:"",
  scanDialog_alertSubHeadline:"",
  scanDialog_alertText:"",

  scanDialog_ImageCardType:"", //image["start", "progress", "success", "error"]
  scanDialog_ImageCardHeadline:"No headline loaded",
  scanDialog_ImageCardSubtext:"No subtext loaded",

  scanDialog_showModeChanger:true,
  scanDialog_dialogBtnText:"Cancel",
};
